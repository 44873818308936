import { render, staticRenderFns } from "./NmtpContract.vue?vue&type=template&id=27668b36&scoped=true&"
import script from "./NmtpContract.vue?vue&type=script&lang=js&"
export * from "./NmtpContract.vue?vue&type=script&lang=js&"
import style0 from "./NmtpContract.vue?vue&type=style&index=0&id=27668b36&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27668b36",
  null
  
)

export default component.exports