export const topMenu = {
  menuItems: [
    {
      label: 'О сотрудничестве',
      component: 'NmtpServices',
    },
    {
      label: 'Договор',
      component: 'NmtpContract',
    },
    {
      label: 'Тарифы',
      component: 'NmtpTariffs',
    },
    {
      label: 'Форма',
      component: 'NmtpForm',
    },
  ],
  button: 'Задать вопрос',
}

export const headerBlock = {
  image: 'header.png',
  title: 'Сотрудничество<br> с ПАО «НМТП»',
  description: 'По аккредитации водителя и&nbsp;грузового автотранспорта',
  button: 'Скачать договор',
  document: '/contract.docx',
  name: 'Договор',
}

export const warningBlock = {
  icon: 'warning.svg',
  title: 'Организациям и ИП заключивших договор «О&nbsp;хозяйственной деятельности» с&nbsp;Новороссийским центральным таможенным портом',
  description: 'Постоянные пропуска подписываются на&nbsp;водителя, согласно трудового договора, с&nbsp;организацией и&nbsp;ИП. Все&nbsp;перевозчики, не&nbsp;имеющие договора на&nbsp;хозяйственную деятельность, заключившие договор, как&nbsp;перевозчик с&nbsp;организацией и&nbsp;ИП, будут оформляться как временные на&nbsp;30 суток с&nbsp;последующим переоформлением.',
}

export const servicesBlock = {
  text: 'ООО «Порт транзит» с 2014 года оказывает <span>услугу по&nbsp;аккредитации</span> водителя, автомобиля и&nbsp;прицепа (полуприцепа), необходимую <span>для&nbsp;получения пропусков</span> на&nbsp;территорию ПАО «НМТП».',
}
export const contractBlock = {
  items: [
    {
      title: 'Договор на оказание <br> услуг по аккредитации',
      button: 'Скачать',
      document: '/contract.docx',
      name: 'Договор',
    },
    {
      title: 'Форма в таможню',
      button: 'Скачать',
      document: '/customs.docx',
      name: 'Форма',
    },
  ],
  contact: {
    title: 'Телефон для получения <br> консультации по аккредитации:',
    phone: '+7 989 776 38 69 ',
    mode: 'Режим работы круглосуточно',
  },
}

export const tariffsBlock = {
  title: 'Наши тарифы:',
  serviceTitle: 'Название услуги',
  priceTitle: 'Цена в т.ч НДС',

  services: [
    {
      title: 'Аккредитация: Водитель + 1 автотранспортное средство (АТС и прицеп)',
      price: '1000',
    },
  ],
}

// данные для самой формы
export const formData = {
  blockTitle: 'Задать вопрос или оставить отзыв по работе, руководству',
  modalTitle: 'Задайте вопрос или оставьте отзыв по работе',
  description: 'Менеджер свяжется с вами в ближайшее время!',
  blockPicture: 'form.png',
  placeholders: {
    name: 'Ваше имя',
    email: 'Ваша почта',
    question: 'Ваш вопрос или отзыв',
  },
  button: 'Отправить',
  checkbox: {
    text: 'Я согласен с ',
    link: 'политикой конфиденциальности',
    url: '/policy.pdf',
  },
}

export const footerBlock = {
  company: {
    name: 'ООО «Порт транзит»',
    inn: 'ИНН 2315114291',
    address: 'Юридический адрес: 353960, Краснодарский край, с Цемдолина, ул Ленина, д. 7м',
  },
  copyright: 'ООО Порт Транзит ©',
}

export const modalRequestBlock = {
  success: {
    title: 'Спасибо, ваша заявка отправлена!',
    description: 'В ближайшее время мы с вами свяжемся!',
  },
  error: {
    title: 'Возникли технические проблемы!',
    description: 'Попробуйте отправить заявку еще раз',
  },
  button: 'Вернуться на сайт',
}
