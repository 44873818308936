<template>
  <section class="wrapper">
    <div class="warning">
      <div class="warning__icon">
        <img :src="imageUrl(content.icon)" alt="header">
      </div>
      <div class="warning__content">
        <div v-html-safe="content.title" class="warning__content-title" />
        <div v-html-safe="content.description" class="warning__content-description" />
      </div>
    </div>
  </section>
</template>

<script>
import { warningBlock } from '@/content/data'
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'NmtpWarning',
  data() {
    return {
      content: warningBlock,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.wrapper
  padding: 0 $desktopHorizontalPaddings $desktopVerticalPaddings
  @media (max-width: 1023px)
    flex-direction: column
    padding: 0 $mobileHorizontalPaddings $mobileVerticalPaddings
.warning
  display: flex
  padding: 3rem 17% 3rem 3rem
  background: #FCECEF
  border-radius: 3.4rem
  @media (max-width: 1023px)
    flex-direction: column
    padding: 2.4rem
    border-radius: 2rem

  &__icon
    flex-shrink: 0
    display: flex
    justify-content: center
    align-items: center
    width: 4.4rem
    height: 4.4rem
    margin-right: 2.4rem
    background: #F9375A
    border-radius: 12px
    @media (max-width: 1023px)
      margin-bottom: 1.4rem

  &__content
    color: $textPrimary
    &-title
      margin-bottom: 1.6rem
      font-size: 2.4rem
      font-weight: 500
      line-height: 3rem
      @media (max-width: 1023px)
        font-size: 2rem
        line-height: 2.4rem
    &-description
      font-family: $textSecondary
      font-size: 1.6rem
      line-height: 2.2rem
      @media (max-width: 1023px)
        font-size: 1.4rem
        line-height: 1.8rem
</style>
