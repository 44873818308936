<template>
  <section class="menu">
    <div class="menu__header">
      <div class="menu__header-logo">
        <img src="@/assets/img/logo-dark.svg" class="picture" alt="logo">
      </div>
      <div class="menu__header-hamburger" @click="showDropdown">
        <img v-if="isDropdownHidden" src="@/assets/img/hamburger.svg" alt="menu">
        <img v-else src="@/assets/img/menu-close.svg" alt="menu">
      </div>
    </div>
    <div :class="['menu__dropdown', { hidden: isDropdownHidden }]">
      <div class="menu__dropdown-items">
        <div
          v-for="(item, index) in menu.menuItems"
          :key="index"
          class="font-small item"
          @click="swipeToBlock(item.component)"
        >
          <div class="item__title">{{ item.label }}</div>
          <div class="item__arrow">
            <arrow-svg />
          </div>
        </div>
      </div>
      <div class="main-button"  @click="showForm">
        {{ menu.button }}
      </div>
    </div>
  </section>
</template>

<script>
import menuMixin from '@/mixins/menuMixin'
import menuMobileMixin from '@/mixins/menuMobileMixin'
export default {
  name: 'FixedMenuMobile',
  mixins: [menuMixin, menuMobileMixin],
}
</script>

<style lang="sass" scoped>
@import "./mobile-styles"
.menu
  position: absolute
  &__header
    background: none
    &-logo
      background: $textPrimary
</style>
