<template>
  <section class="menu-desktop">
    <div class="menu__wrapper">
      <div class="menu__logo">
        <img src="@/assets/img/logo-dark.svg" alt="logo" class="menu__logo-picture">
      </div>
      <div class="menu__items">
        <div
          v-for="(item, index) in menu.menuItems"
          :key="index"
          class="menu__items-item"
          @click="goToSection(item.component)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="main-button" @click="$emit('show-form')">Задать вопрос</div>
  </section>
</template>

<script>
import menuMixin from '@/mixins/menuMixin'
export default {
  name: 'FixedMenuDesktop',
  mixins: [menuMixin],
}
</script>

<style lang="sass" scoped>
.menu-desktop
  position: absolute
  top: 0
  left: 0
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  padding: 2.9rem 6rem
  @media (min-width: 1280px) and (max-width: 1599px)
    padding: 2.9rem 4rem
  @media (min-width: 1024px) and (max-width: 1279px)
    padding: 2.9rem 3rem
  z-index: 5
  @media (max-width: 1023px)
    display: none
  .menu__wrapper
    display: flex
    align-items: center
  .menu__logo
    display: flex
    align-items: center
    width: $desktopHorizontalPaddings
    &-picture
      width: 244px
      height: 42px
      object-fit: contain
      object-position: center
      background-color: $textPrimary
      @media (min-width: 1280px) and (max-width: 1599px)
        width: 171px
        height: 30px
      @media (min-width: 1024px) and (max-width: 1279px)
        width: 143px
        height: 25px
  .menu__items
    flex: 1
    display: flex
    align-items: center
    padding-left: 22rem
    @media (min-width: 1440px) and (max-width: 1919px)
      padding-left: 16rem
    @media (min-width: 1024px) and (max-width: 1439px)
      padding-left: 14rem
    &-item
      margin-right: 3rem
      font-family: $secondaryFont
      font-size: 1.6rem
      color: $textLightWhite
      cursor: pointer
      transition: color .3s ease
      @media (min-width: 1440px) and (max-width: 1599px)
        margin-right: 3rem
      &:hover
        color: $buttonPrimary
        border-bottom: 1px solid $buttonPrimary
</style>
