<template>
  <section ref="anchor" class="tariffs">
    <div class="tariffs__title">{{ content.title }}</div>
    <div class="tariffs__headers">
      <div>{{ content.serviceTitle }}</div>
      <div>{{ content.priceTitle }}</div>
    </div>
    <div class="tariffs__services">
      <div v-for="(service, index) in content.services" :key="index" class="tariffs__services-item">
        <div class="font2-18-24">{{ service.title }}</div>
        <div class="price">{{ service.price }}&nbsp;₽ </div>
      </div>
    </div>
  </section>
</template>

<script>
import { tariffsBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'

export default {
  name: 'NmtpTariffs',
  mixins: [offsetMixin],
  data() {
    return {
      content: tariffsBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.tariffs
  padding: 0 $desktopHorizontalPaddings $desktopVerticalPaddings
  @media (max-width: 1023px)
    padding: 0 $mobileHorizontalPaddings $mobileVerticalPaddings

  &__title
    margin-bottom: 40px
    font-weight: 500
    font-size: 4rem
    line-height: 4.8rem
    @media (max-width: 1023px)
      margin-bottom: 32px
      font-size: 3.2rem
      line-height: 4rem

  &__headers
    display: flex
    justify-content: space-between
    font-family: $secondaryFont
    font-weight: 500
    font-size: 1.6rem
    line-height: 2rem
    color: $textGray

  &__services
    margin-top: 18px
    @media (max-width: 1023px)
      margin-top: 12px
    &-item
      display: flex
      justify-content: space-between
      align-items: center
      padding: 17px 0
      border-top: 1px solid $tileBackground
      @media (max-width: 1023px)
        flex-direction: column
        align-items: flex-start
        padding: 12px 0
      &:first-of-type
        border-top: none
      .price
        width: 20%
        white-space: nowrap
        font-weight: 500
        font-size: 2.4rem
        line-height: 3rem
        text-align: end
        @media (max-width: 1023px)
          margin-top: 8px
          font-size: 2rem
          line-height: 2.4rem
          text-align: left
</style>
