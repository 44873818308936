import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import YmapPlugin from 'vue-yandex-maps'
const settings = {
  apiKey: '5f3e48e4-ad99-4cb4-ab1e-2b7965aa7a90',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
}

Vue.use(YmapPlugin, settings)

import VueSecureHTML from 'vue-html-secure'
Vue.use(VueSecureHTML)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import './assets/styles/main.sass'
import App from './App.vue'
import { store } from './store'

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
