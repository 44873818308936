<template>
  <section :class="['menu', { hidden: !isVisible }]">
    <div
      :class="['menu__header', { hidden: !isVisible, light: isDropdownHidden }]"
    >
      <div class="menu__header-logo" @click="goToSection('HeaderComponent')">
        <img
          v-if="isDropdownHidden"
          src="@/assets/img/logo-light.svg"
          class="picture"
          alt="logo"
        />
        <img
          v-else
          src="@/assets/img/logo-dark.svg"
          class="picture"
          alt="logo"
        />
      </div>
      <div class="menu__header-hamburger" @click="showDropdown">
        <img
          v-if="isDropdownHidden"
          src="@/assets/img/hamburger.svg"
          alt="menu"
        />
        <img v-else src="@/assets/img/menu-close.svg" alt="menu" />
      </div>
    </div>
    <div :class="['menu__dropdown', { hidden: isDropdownHidden }]">
      <div class="menu__dropdown-items">
        <div
          v-for="(item, index) in menu.menuItems"
          :key="index"
          :class="[
            'font-small',
            'item',
            { active: item.component === visibleElement },
          ]"
          @click="swipeToBlock(item.component)"
        >
          <div class="item__title">{{ item.label }}</div>
          <div class="item__arrow">
            <arrow-svg />
          </div>
        </div>
      </div>
      <div class="main-button" @click="showForm">
        {{ menu.button }}
      </div>
    </div>
  </section>
</template>

<script>
import menuMixin from '@/mixins/menuMixin'
import menuMobileMixin from '@/mixins/menuMobileMixin'
import { mapGetters } from 'vuex'
export default {
  name: 'FloatMenuMobile',
  mixins: [menuMixin, menuMobileMixin],
  data() {
    return {
      currentScroll: 0,
      visibleElement: '',
    }
  },
  computed: {
    ...mapGetters({
      offsets: 'getOffsets',
    }),
    isVisible() {
      return this.$store.getters.getOffsets.NmtpServices < this.currentScroll
    },
  },
  mounted() {
    this.scrollHandler()
    document.addEventListener('scroll', this.scrollHandler)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollHandler)
  },
  methods: {
    // получение текущего скролла документа при прокрутке
    scrollHandler() {
      this.visibleElement = ''
      const menu = this.menu.menuItems

      this.currentScroll =
        window.pageYOffset || document.documentElement.scrollTop
      for (let i = 0; i < menu.length - 1; i++) {
        if (
          this.currentScroll > this.offsets[menu[i].component] &&
          this.currentScroll < this.offsets[menu[i + 1].component]
        ) {
          this.visibleElement = menu[i].component
          break
        }
      }
      if (!this.visibleElement.length) {
        this.visibleElement = 'NmtpForm'
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "./mobile-styles"
.menu
  position: fixed
  transition: all 0.5s ease
  transition-property: visibility, opacity
  &.hidden
    opacity: 0
    visibility: hidden
.menu__header
  transition: transform .5s ease
  &.light
    background: $textWhite
    border-bottom: 1px solid $backgroundGray
  &.hidden
    transform: translateY(-100%)
  &-logo
    cursor: pointer
.menu__dropdown-items .item.active
  .item__title
    color: $buttonPrimary
  svg
    stroke: $buttonPrimary
</style>
