<template>
  <section ref="anchor" v-html-safe="content.text" class="services" />
</template>

<script>
import { servicesBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'

export default {
  name: 'NmtpServices',
  mixins: [offsetMixin],
  data() {
    return {
      content: servicesBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.services
  padding: $desktopVerticalPaddings $desktopHorizontalPaddings
  font-weight: 500
  font-size: 3.17rem
  line-height: 4rem
  @media (max-width: 1023px)
    padding: $mobileVerticalPaddings $mobileHorizontalPaddings
    font-size: 2rem
    line-height: 2.6rem

  ::v-deep span
    color: $buttonPrimary
</style>
