<template>
  <section ref="anchor" class="contract">
    <div class="contract__block contract__sample">
      <div class="line mod-max" />
      <div class="line mod-middle" />
      <div class="line mod-min" />
      <div v-for="(item, index) in content.items" :key="index">
        <div v-html-safe="item.title" class="contract__sample-title" />
        <more-button :title="item.button" :document="item.document" :name="item.name"/>
      </div>
    </div>
    <div class="contract__block contract__contacts">
      <div v-html-safe="content.contact.title" class="contract__contacts-title" />
      <div class="contract__contacts-phone">{{ content.contact.phone }}</div>
      <div class="contract__contacts-mode">{{ content.contact.mode }}</div>
    </div>
  </section>
</template>

<script>
import { contractBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'
import MoreButton from '@/components/common/MoreButton'

export default {
  name: 'NmtpContract',
  components: { MoreButton },
  mixins: [offsetMixin],
  data() {
    return {
      content: contractBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.contract
  display: flex
  justify-content: space-between
  padding: 0 $desktopHorizontalPaddings $desktopVerticalPaddings
  @media (max-width: 1023px)
    flex-direction: column
    padding: 0 $mobileHorizontalPaddings $mobileVerticalPaddings

  &__block
    height: 23.1rem
    padding: 3rem
    border-radius: 35px
    @media (max-width: 1919px)
      padding: 2.4rem
      border-radius: 20px
    @media (max-width: 1023px)
      height: 20rem
  &__sample
    position: relative
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 66.17%
    background: $textPrimary
    overflow: hidden
    @media (max-width: 1023px)
      width: 100%
    &-title
      position: relative
      width: fit-content
      background: $textPrimary
      color: $textWhite
      font-weight: 500
      font-size: 2.4rem
      line-height: 3rem
      z-index: 1
      @media (min-width: 1024px)
        font-size: 2.8rem
        line-height: 3.6rem
  &__contacts
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 32.17%
    background: $textLightWhite
    @media (max-width: 1023px)
      width: 100%
      margin-top: 20px
    &-title
      font-weight: 500
      font-size: 2rem
      line-height: 2.4rem
      @media (max-width: 1919px)
        font-size: 1.8rem
        line-height: 2.2rem
    &-phone
      margin-bottom: 4px
      font-weight: 500
      font-size: 3.2rem
      line-height: 4rem
      color: $buttonPrimary
      @media (max-width: 1919px)
        font-size: 2.4rem
        line-height: 3rem
    &-mode
      margin-top: 20px
      font-family: $secondaryFont
      font-size: 1.4rem
      line-height: 1.8rem
      color: $textGray
      @media (max-width: 1023px)
        font-size: 1.2rem
        line-height: 1.6rem
// декоративные линии
.line
  position: absolute
  top: 0
  right: 0
  border-left: 1px solid $buttonPrimary
  border-bottom: 1px solid $buttonPrimary
  &.mod-max
    width: 18.9rem
    height: 20.8rem
    border-radius: 0 0 0 16rem
    @media (min-width: 1024px)
      width: 25.9rem
      height: 23.6rem
  &.mod-middle
    width: 13.9rem
    height: 15.8rem
    border-radius: 0 0 0 12rem
    @media (min-width: 1024px)
      width: 19.9rem
      height: 17.6rem
  &.mod-min
    width: 8.9rem
    height: 10.8rem
    border-radius: 0 0 0 7rem
    @media (min-width: 1024px)
      width: 13.9rem
      height: 11.6rem
</style>
