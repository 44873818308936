<template>
  <header>
    <div class="header">
      <div class="header__block mod-left">
        <div class="header__block-top" />
        <div class="header__block-bottom" />
      </div>
      <div class="header__block mod-right">
        <div v-html-safe="content.title" class="header__block-top" />
        <div class="header__block-bottom">
          <div v-html-safe="content.description" class="description" />
          <more-button :title="content.button" :document="content.document" :name="content.name" />
        </div>
      </div>
      <div class="header__picture">
        <div class="header__picture-wrapper">
          <img :src="imageUrl(content.image)" alt="header">
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="mobile__block mod-left">
        <div class="mobile__block-top" />
        <div class="mobile__block-bottom" />
      </div>
      <div class="mobile__block mod-right">
        <div class="mobile__block-top">
          <div v-html-safe="content.title" class="title" />
          <div v-html-safe="content.description" class="description" />
          <MoreButton :title="content.button" />
        </div>
        <div class="mobile__block-bottom">
          <img :src="imageUrl(content.image)" alt="header" class="picture">
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import MoreButton from '@/components/common/MoreButton'
import { headerBlock } from '@/content/data'
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'HeaderComponent',
  components: { MoreButton },
  data() {
    return {
      content: headerBlock,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.header
  display: flex
  background: $textPrimary
  @media (max-width: 1023px)
    display: none
  &__block.mod-left
    flex: 1
    .header__block-top
      border-right: 1px solid $buttonPrimary
      border-bottom: 1px solid $buttonPrimary
      border-radius: 0 0 100px 0
    .header__block-bottom
      border-right: 1px solid $buttonPrimary
      border-top: 1px solid $buttonPrimary
      border-radius: 0 100px 0 0
  &__block.mod-right
    width: 47.65vw
    margin-left: 40px
    .header__block-top
      display: flex
      flex-direction: column
      justify-content: flex-end
      padding: 5rem
      border-left: 1px solid $buttonPrimary
      border-bottom: 1px solid $buttonPrimary
      border-radius: 0 0 0 100px
      font-weight: 500
      font-size: 9rem
      line-height: 11.6rem
      color: $textWhite
      @media (min-width: 1600px) and (max-width: 1919px)
        font-size: 8rem
        line-height: 10rem
      @media (min-width: 1024px) and (max-width: 1599px)
        font-size: 6rem
        line-height: 8rem
        padding: 5rem 4rem
    .header__block-bottom
      display: flex
      justify-content: space-between
      align-items: flex-start
      padding: 5rem
      border-left: 1px solid $buttonPrimary
      border-top: 1px solid $buttonPrimary
      border-radius: 100px 0 0 0
      @media (min-width: 1024px) and (max-width: 1599px)
        padding: 5rem 4rem
      .description
        width: 47rem
        font-family: $secondaryFont
        font-size: 2rem
        color: $textWhite
      .more
        min-width: 16.8rem

  &__block-top
    height: 52.6rem
    margin-bottom: 40px
  &__block-bottom
    height: 27.4rem

  &__picture
    width: 33.6vw
    &-wrapper
      height: 100%
      img
        width: 100%
        height: 100%
        object-fit: cover

.mobile
  position: relative
  display: flex
  background: $textPrimary
  @media (min-width: 1024px)
    display: none
  &__block.mod-left
    position: absolute
    top: 0
    left: -50px
    width: calc(50px + 12.2vw - 20px)
    .mobile__block-top
      border-right: 1px solid $buttonPrimary
      border-bottom: 1px solid $buttonPrimary
      border-radius: 0 0 50px 0
    .mobile__block-bottom
      border-right: 1px solid $buttonPrimary
      border-top: 1px solid $buttonPrimary
      border-radius: 0 50px 0 0
  &__block.mod-right
    width: 88.8vw
    margin-left: 12.2vw
    .mobile__block-top
      display: flex
      flex-direction: column
      justify-content: flex-end
      padding: 2.5rem 1.8rem
      border-left: 1px solid $buttonPrimary
      border-bottom: 1px solid $buttonPrimary
      border-radius: 0 0 0 50px
      .title
        margin-bottom: 1.2rem
        font-weight: 500
        font-size: 3.2rem
        line-height: 4rem
        color: $textWhite
      .description
        margin-bottom: 3.7rem
        font-family: $secondaryFont
        font-size: 1.6rem
        line-height: 2.2rem
        color: $textWhite
        @media (min-width: 768px)
          width: 50%
      .more
        align-self: end
    .mobile__block-bottom
      border-left: 1px solid $buttonPrimary
      border-top: 1px solid $buttonPrimary
      border-radius: 50px 0 0 0
      .picture
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50px 0 0 0

  &__block-top
    height: 34.4rem
    margin-bottom: 20px
  &__block-bottom
    height: 18.6rem
</style>
