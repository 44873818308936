<template>
  <modal-wrapper :visible="visible" v-on="$listeners">
    <template slot="content">
      <div class="content__title">{{ content.modalTitle }}</div>
      <div class="content__description">{{ content.description }}</div>
      <lead-form v-on="$listeners"/>
    </template>
  </modal-wrapper>
</template>

<script>
import { formData } from '@/content/data'
import LeadForm from '@/components/common/Form/LeadForm'
import ModalWrapper from '@/components/common/Modals/ModalWrapper'

export default {
  name: 'ModalRequestResult',
  components: { ModalWrapper, LeadForm },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: formData,
    }
  },
}
</script>

<style lang="sass" scoped>
.content__title
  margin-bottom: 1.2rem
  font-weight: 500
  font-size: 32px
  line-height: 40px
  color: $textPrimary
  @media (max-width: 540px)
    font-size: 22px
    line-height: 27px

.content__description
  margin-bottom: 2.3rem
  font-family: $secondaryFont
  font-size: 16px
  line-height: 22px
  color: $textGray

::v-deep .main-button
  width: 100%

</style>
