<template>
  <modal-wrapper :visible="visible" v-on="$listeners">
    <template v-if="result.length" slot="content">
      <div class="request__title">{{ content[result].title }}</div>
      <div class="request__description">{{ content[result].description }}</div>
      <div class="main-button" @click="$emit('close')">{{ content.button }}</div>
    </template>
  </modal-wrapper>
</template>

<script>
import { modalRequestBlock } from '@/content/data'
import ModalWrapper from '@/components/common/Modals/ModalWrapper'

export default {
  name: 'ModalRequestResult',
  components: { ModalWrapper },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    result: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      content: modalRequestBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.request__title
  margin-bottom: 1.2rem
  font-weight: 500
  font-size: 32px
  line-height: 40px
  color: $textPrimary
  @media (max-width: 540px)
    font-size: 22px
    line-height: 27px
.request__description
  margin-bottom: 2.3rem
  font-family: $secondaryFont
  font-size: 16px
  line-height: 22px
  color: $textGray
.request__button
  display: flex
  justify-content: center
  align-items: center
  height: 5rem
  border-radius: 50px
  background: $buttonPrimary
  color: $textWhite
  cursor: pointer
  font-family: $primaryFont
  font-weight: 400
  font-size: 1.6rem
  line-height: 2.2rem
  transition: background .3s ease
  &:hover
    background: $buttonHover
  &:active
    background: $buttonClicked

::v-deep .main-button
  width: 100%
</style>
