<template>
  <section class="landing">
    <fixed-menu-desktop @show-form="showForm" />
    <fixed-menu-mobile @show-form="showForm" />
    <float-menu-mobile @show-form="showForm" />
    <header-component @show-form="showForm" />
    <nmtp-services />
    <nmtp-warning />
    <nmtp-contract />
    <nmtp-tariffs />
    <nmtp-form @requested="showRequestModal" />
    <footer-component />
    <modal-request-result
      :visible="modalRequestOpened"
      :result="requestResult"
      @close="modalRequestOpened = false"
    />
    <modal-form
      :visible="modalFormOpened"
      @requested="showRequestModal"
      @close="closeForm"
    />
  </section>
</template>

<script>
import FixedMenuDesktop from '@/components/menu/FixedMenuDesktop'
import FixedMenuMobile from '@/components/menu/FixedMenuMobile'
import FloatMenuMobile from '@/components/menu/FloatMenuMobile'
import FooterComponent from '@/components/Footer.vue'
import HeaderComponent from '@/components/Header.vue'
import NmtpServices from '@/components/NmtpServices'
import NmtpWarning from '@/components/NmtpWarning'
import NmtpContract from '@/components/NmtpContract'
import NmtpTariffs from '@/components/NmtpTariffs'
import NmtpForm from '@/components/NmtpForm'
import ModalRequestResult from '@/components/common/Modals/ModalRequestResult'
import ModalForm from '@/components/common/Modals/ModalForm'

export default {
  name: 'LandingPage',
  components: {
    FixedMenuDesktop,
    FixedMenuMobile,
    FloatMenuMobile,
    HeaderComponent,
    NmtpServices,
    NmtpWarning,
    NmtpContract,
    NmtpTariffs,
    NmtpForm,
    FooterComponent,
    ModalRequestResult,
    ModalForm,
  },
  data() {
    return {
      modalRequestOpened: false,
      modalFormOpened: false,
      requestResult: '',
    }
  },
  methods: {
    // открытие модальной формы заявки
    showForm() {
      this.modalFormOpened = true
    },
    // вывод сообщения о результате отправки
    showRequestModal(result) {
      this.modalFormOpened = false
      this.requestResult = result
      this.modalRequestOpened = true
    },
    closeForm() {
      this.modalFormOpened = false
      document.body.style.overflow = 'auto'
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .v-modal
  background: rgba(25, 31, 38, 0.7)
</style>
