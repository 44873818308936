<template>
  <section ref="anchor" class="form">
    <div class="form__block mod-image">
      <div
        :style="{ 'background-image': 'url(' + imageUrl(content.blockPicture) + ')' }"
        class="form__block-image"
      />
    </div>
    <div class="form__block mod-info">
      <div class="form__block-title">{{ content.blockTitle }}</div>
      <lead-form v-on="$listeners" />
    </div>
  </section>
</template>

<script>
import LeadForm from '@/components/common/Form/LeadForm'
import { imageUrl } from '@/helpers/imageLoading'
import { formData } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'
export default {
  name: 'NmtpForm',
  components: { LeadForm },
  mixins: [offsetMixin],
  data() {
    return {
      content: formData,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.form
  display: flex
  flex-wrap: wrap
  padding: 0 $desktopHorizontalPaddings
  margin-bottom: $desktopVerticalPaddings
  border-top: 1px solid $backgroundGray
  border-bottom: 1px solid $backgroundGray
  @media (max-width: 1023px)
    padding: 0 $mobileHorizontalPaddings
    margin-bottom: $mobileVerticalPaddings
    flex-direction: column
  &__block
    display: flex
    flex-direction: column
    width: 50%
    border-left: 1px solid $tileBackground
    @media (max-width: 1023px)
      width: 100%
    &.mod-info
      display: flex
      flex-direction: column
      justify-content: space-between
      padding: 3.4rem
      border-right: 1px solid $tileBackground
      @media (max-width: 1023px)
        padding: 3.2rem 2rem
        border-bottom: 1px solid $tileBackground
    &.mod-image
      padding: 2.4rem
      @media (max-width: 1023px)
        padding: 2rem
        border-bottom: 1px solid $tileBackground
        border-right: 1px solid $tileBackground

    &-title
      margin-bottom: 3.2rem
      font-weight: 500
      font-size: 3.2rem
      line-height: 4rem
      @media (max-width: 1919px)
        margin-bottom: 2.4rem
        font-size: 2rem
        line-height: 2.4rem

    &-image
      height: 100%
      border-radius: 34px
      background-repeat: no-repeat
      background-size: cover
      @media (max-width: 1023px)
        height: calc(100vw - 10rem)
        max-height: 600px
</style>
